.page {
    box-shadow: 0px 5px 15px black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    max-width: 500px;
    margin: 20px auto 0 auto;
    overflow: hidden;
}

@media only screen and (max-width: 500px)  {
    body .page,
    body .header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
    }
}

.header {
    text-align: center;
    background-color: var(--color5);
    color: var(--color5-contrast);
    padding: 5px 0;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.header--title {
    margin: 0;
}

.header--tagline {
    font-size: 1em;
    font-weight: normal;
}

.main {
    background-color: var(--color3);
    color: var(--color3-contrast);
    padding: 5px 10px 20px 10px;
}

.button {
    width: 100%;
    display: block;
    background-color: var(--color5);
    color: var(--color5-contrast);
    outline: none;
    border: none;
    padding: 10px 10px;
    font-size: 1em;
}

.start-simulation {
    margin-top: 20px;
}