.play-again {
    margin-top: 10px;
    animation: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

@keyframes pulse {
    from { transform: scale(0.90); }
    to   { transform: scale(1.0);  }
}