.card {
    display: inline-block;
    width: 65px;
    height: 90px;
    border: 2px solid black;
    margin: 2px;
    background-color: white;
    text-align: center;
    position: relative;
    box-shadow: 2px 7px 10px 0px #222;
    border-radius: 5px;
}

.card--suit--spades .card--suit:before,
.card--suit--spades .card--value:after,
.suit--spades:before { content: '\2660' }
.card--suit--hearts .card--suit:before,
.card--suit--hearts .card--value:after,
.suit--hearts:before { content: '\2665' }
.card--suit--diamonds .card--suit:before,
.card--suit--diamonds .card--value:after,
.suit--diamonds:before { content: '\2666' }
.card--suit--clubs .card--suit:before,
.card--suit--clubs .card--value:after,
.suit--clubs:before { content: '\2663' }

.card--suit:before {
    display: inline-block;
    line-height: 85px;
    position: relative;
    height: 90px;
    font-size: 40px;
    z-index: 10;
}

.card--value {
    position: absolute;
    top: 2px;
    left: 4px;
    font-size: 20px;
    height: 20px;
    line-height: 20px;
}

.card--value:after {
    position: absolute;
    top: 17px;
    left: 0px;
}

.card--suit--hearts,
.card--suit--diamonds,
.suit--hearts,
.suit--diamonds
{ color: #ce0000; }

.card--suit--spades,
.card--suit--clubs,
.suit--spades,
.suit--clubs
{ color: black;}