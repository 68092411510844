* {
  box-sizing: border-box;

  /* Color scheme generated based https://coolors.co/393e41-d3d0cb-e2c044-587b7f-1e2019, with some tweaks */
  --color1: #393e41;
  --color1-contrast: white;
  --color2: #d3d0cb;
  --color2-contrast: black;
  --color3: #d4caf1;
  --color3-contrast: black;
  --color4: #a793c8;
  --color4-contrast: white;
  --color5: #1e2019;
  --color5-contrast: white;
}

body {
  background-color: var(--color1);
  color: var(--color1-contrast);

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}