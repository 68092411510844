.card--hand--title {
    text-align: center;
    text-shadow: 1px 0px 5px black;
}

.card--hand {
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 200px;
    min-width: 45%;
    background-color: var(--color4);
    color: var(--color4-contrast);
    padding: 0 20px;
    z-index: 10;
    box-shadow: 2px 2px 5px black;
}

.card--hand--won {
    animation-name: victory-glow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
}

@keyframes victory-glow {
    from { background-color: #328f00; }
    to {   background-color: #2cc103; }
}

.card--hand--score {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 1.5em;
    font-weight: bold;
    animation: text-spin;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

.card--hand--score--exact {
    color: #00ce00;
}
.card--hand--score--high {
    color: #552222;
}

@keyframes text-spin {
    0% {
        transform: scale(0);
    }
    80% {
        transform: rotateY(360deg) scale(0);
    }
    100% {
        transform: rotateY(0) scale(1);
    }
}

.card--list--entry {
    overflow-x: visible;
    width: 20px;
    position: relative;
    display: inline-block;
    animation-name: card-dealt;
    animation-duration: 1.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

@keyframes card-dealt {
    from {
        transform: rotate(180deg);
        top: 800px;
        left: 100px;
    }
    to {
        top: 0;
        left: 0;
    }
}

.card--list--entry:nth-child(1) { transform: rotate(0); }
.card--list--entry:nth-child(2) { transform: rotate(10deg); }
.card--list--entry:nth-child(3) { transform: rotate(20deg); }
.card--list--entry:nth-child(4) { transform: rotate(30deg); }
.card--list--entry:nth-child(5) { transform: rotate(40deg); }
.card--list--entry:nth-child(6) { transform: rotate(50deg); }