
.event-log {
    margin-top: 20px;
    min-height: 120px;
}

.event-log--entry--init {
    font-style: italic;
}

.event-log--entry {
    display: flex;
    justify-content: space-between;
}

.event-log--entry--result {
    font-weight: bold;
}

.card--text {
    padding-left: 10px;
}
